import React from "react";
import {Link} from "gatsby";
import DefaultLayout from "components/layout/default";
import {IMAGE_BACKGROUND_PROPS} from "constants/index";
import backgroundImage from "images/background-404.jpg";
import style from "styles/pages/404.module.css";

function NotFoundPage(props) {
  return <DefaultLayout location={props.location}
    title="No page found – Horangi" headerFill="always">
    <div className="mx-auto text-center relative flex items-center justify-center min-h-screen mb-16">
      <div className="font-bold absolute z-10 inset-0 w-full h-full flex items-center justify-center">
        <h3 className={style.headerText}>404</h3>
      </div>
      <div className="absolute z-20 inset-0 w-full h-full flex items-center justify-center">
        <div>
          <h1 className="text-2xl uppercase font-bold">No page found</h1>
          <p className="mt-2">There‘s so much fog that tigers can‘t see anything...</p>
          <Link to="/" className="btn btn-orange mt-6 inline-block">Go Back Home</Link>
        </div>
      </div>
      <img {...IMAGE_BACKGROUND_PROPS} className="object-cover object-center"
        src={backgroundImage} alt="Background"/>
    </div>
  </DefaultLayout>;
}

export default React.memo(NotFoundPage);
